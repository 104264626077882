
.wrapper {
  position: relative;
  overflow: hidden;
}

.wrapper #cancel-btn i{
  position: absolute;
  font-size: 16px;
  right: 15px;
  top: 15px;
  color: #9658fe;
  cursor: pointer;
  display: block;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper .btn {
  border: 1px solid gray;
  color: gray;
  background-color: white;
  border-radius: 8px;
  font-weight: bold;
  @media (min-width: 0px) and (max-width: 700px) {
    font-size: 9px;
  }
}

.upload-btn-wrapper input[type=file] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.custom-row-upload {

  .custom-col-upload {
    @media (min-width: 0px) and (max-width: 1000px) {
      flex-wrap: wrap;
    }

    .custom-item-upload {
      @media (min-width: 0px) and (max-width: 1000px) {
        width: 44% !important;
      }
      @media (min-width: 0px) and (max-width: 700px) {
        width: 44% !important;
      }
      @media (min-width: 0px) and (max-width: 300px) {
        width: 95% !important;
      }
    }
  }
}